import MetaTags from 'react-meta-tags';

function Support() {
    return (
        <div>
            <MetaTags>
                <meta property="og:title" content="Soceton Manager - Terms and Conditions" />
                <meta property="og:image" content="https://www.soceton.com/favicon.ico" />
                <meta property="og:url" content="https://www.soceton.com/terms-and-conditions" />
                <meta property="og:type" content="Article" />
                <meta property="og:description" content="Manage Income and Expenses" />
                <meta property="og:app_id" content="172362581543762" />
            </MetaTags>
            <div>
                <h3 className={"contact"}>Contact Us: contact@soceton.com</h3>
            </div>
        </div>
    )
}

export default Support;