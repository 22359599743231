import React from "react";
import logo from "../icon.png";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import {useEffect} from "react";
import Footer from "./Footer";


export const RedirectUrl = ({url}) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    console.log(query.get('ref'))
    return <h5>Redirecting...</h5>;
};

export default function Main() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Coming soon...
                </p>
                <Link to={"seller/comana"}>Soceton Seller: Keyword Competition Analysis for Amazon (BETA)</Link>
                <a
                    className="App-link"
                    href="https://play.google.com/store/apps/details?id=com.soceton.manager"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Download Soceton Manager from Play Store
                </a>
                <Link to={"privacy-policy"}>Soceton Manager Privacy Policy</Link>
                <Link to={"seller-privacy-policy"}>Soceton Seller Privacy Policy</Link>
                <Link to={"terms-and-conditions"}>Terms and Conditions</Link>
                <Link to={"support"}>Support</Link>
            </header>
            <Footer/>
        </div>
    );
}
