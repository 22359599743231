
function SocetonSellerThankYou() {
    return (
        <div>
            <div className="content">
                <div className="wrapper-1">
                    <div className="wrapper-2">
                        <h1>Thank you !</h1>
                        <p>Thanks for installing <strong>Soceton Seller - Keyword Competition Analysis.</strong> </p>
                        <p>Enjoy easy amazon product/keyword research from now.</p>
                        <br />
                        <br />
                        <br />
                        <a className="go-home" href={"/"}>
                            go home
                        </a>
                    </div>
                    <div className="footer-like">
                        {/*<p>Email not received?*/}
                        {/*    <a href="/">Click here to send again</a>*/}
                        {/*</p>*/}
                        <h3>Contact Us: contact@soceton.com</h3>
                    </div>
                </div>
            </div>
        </div>
)
}

export default SocetonSellerThankYou;
