import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import PrivacyPolicy from "./components/external/PrivacyPolicy";
import TermsAndConditions from "./components/external/TermsAndConditions";
import Support from "./components/external/Support";
import SocetonSellerPrivacyPolicy from "./components/external/SocetonSellerPrivacyPolicy";
import SocetonSellerThankYou from "./components/external/SocetonSellerThankYou";
import Index, {RedirectUrl} from "./components/Main";
import SocetonSeller from "./components/products/SocetonSeller";


function App() {
    return (
        <Router>
            <Switch>
                <Route path="/privacy-policy" exact>
                    <PrivacyPolicy/>
                </Route>
                <Route path="/terms-and-conditions" exact>
                    <TermsAndConditions/>
                </Route>
                <Route path="/seller-privacy-policy" exact>
                    <SocetonSellerPrivacyPolicy/>
                </Route>
                <Route path="/support" exact>
                    <Support/>
                </Route>
                <Route path="/seller/thank-you" exact>
                    <SocetonSellerThankYou/>
                </Route>
                <Route path="/seller/comana" exact>
                    <SocetonSeller/>
                </Route>
                <Route path="/seller/competition-analysis" exact>
                    <RedirectUrl url="https://soceton.com/seller/comana"/>
                </Route>
                <Route path="/" exact>
                    <Index/>
                </Route>
            </Switch>
        </Router>
    )
}

export default App;
