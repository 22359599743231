import MetaTags from 'react-meta-tags';

function SocetonSellerPrivacyPolicy() {
    return (
        <div>
            <MetaTags>
                <meta property="og:title" content="Soceton Seller - Privacy Policy"/>
                <meta property="og:image" content="https://www.soceton.com/soceton-manager.png"/>
                <meta property="og:url" content="https://www.soceton.com/seller-privacy-policy"/>
                <meta property="og:type" content="Article"/>
                <meta property="og:description" content="Tool for you to analyze keyword competition for Amazon."/>
            </MetaTags>
            <h1>Privacy Policy for <strong>Soceton Seller</strong></h1>
            <p>Last updated: Nov 21, 2023</p>

            <section>
                <h2>Introduction</h2>
                <p>Welcome to <strong>Soceton Seller</strong> ("we," "us," or "our"). This Privacy Policy outlines the
                    information we
                    collect and how we use, share, and protect that information in the course of providing our
                    services.</p>
            </section>

            <section>
                <h2>Information We Collect</h2>
                <ol>
                    <li><strong>ActiveTab Data:</strong> Our extension collects data from the activeTab on Amazon
                        websites exclusively.
                        This data is utilized for analysis conducted in the background for that specific tab.
                    </li>
                    <li><strong>Permissions for External Host:</strong> The extension may access multiple tabs for
                        adding permissions
                        from external hosts, enhancing the user experience and functionality.
                    </li>
                    <li><strong>Data Storage:</strong> <strong>Soceton Seller</strong> acts as the storage container for
                        caching loaded
                        data from APIs. This data storage is essential for optimizing performance and providing a
                        seamless user
                        experience.
                    </li>
                    <li><strong>Content Script Execution:</strong> The extension is used to execute content scripts and
                        other CSS
                        files for analytics, displaying information on the activeTab.
                    </li>
                </ol>
            </section>

            <section>
                <h2>Use of Collected Information</h2>

                <p><strong>Amazon Websites:</strong> We declare that our extension is designed to collect data
                    exclusively from Amazon websites. The data collected is processed and analyzed for the purpose of
                    enhancing the user experience on these platforms.</p>

                <p><strong>API URL:</strong> Our extension requires permission to access API URLs for processing and
                    analyzing the collected data. This permission is solely for the purpose of providing the intended
                    functionality of the extension.</p>

                <p><strong>Remote Script or Backend Processing:</strong> The data collected may be processed and
                    analyzed using remote scripts or in the backend. This ensures efficient and effective utilization of
                    the collected information to provide valuable insights.</p>
            </section>

            <section>
                <h2>Information Sharing</h2>

                <p>We do not share personal information with third parties. Any data shared for analysis purposes is
                    anonymized and aggregated to protect user privacy.</p>
            </section>

            <section>
                <h2>Security</h2>

                <p>We implement reasonable security measures to protect the information collected. However, it's
                    important to note that no method of transmission or storage is entirely secure.</p>
            </section>

            <section>
                <h2>Changes to This Privacy Policy</h2>

                <p>We reserve the right to update our Privacy Policy to reflect changes in our practices. Users will be
                    notified of any changes through an updated version of this policy.</p>
            </section>

            <footer>
                <p>By using our Chrome extension, you agree to the terms outlined in this Privacy Policy.</p>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at contact@soceton.com.</p>
            </footer>
        </div>

    );
}

export default SocetonSellerPrivacyPolicy;
